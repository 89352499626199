"use client";

import { useParams, usePathname } from "next/navigation";
import classNames from "classnames";
import { FC, memo, MouseEvent, useCallback, useMemo } from "react";
import { ISportFeedResponse } from "@finbackoffice/websocket-client";
import { Svg } from "components/base/svg/Svg";
import ActiveLink from "components/base/active-link/ActiveLink";
import { RouteNames } from "utils/constants";
import SportIcon from "components/base/sport-icon/SportIcon";
import styles from "./sport.module.sass";

interface IProps {
    sport: ISportFeedResponse;
    active?: boolean;
    onClickHandler?: (sportId: number) => void;
    hasFavorite: boolean;
    showGamesCount?: boolean;
    variant?: "link" | "button";
}

const Sport: FC<IProps> = ({
    sport,
    active,
    onClickHandler,
    hasFavorite,
    showGamesCount = true,
    variant = "link",
}) => {
    const pathname = usePathname();
    const params = useParams();
    const onClick = useCallback(
        (event: MouseEvent<HTMLAnchorElement | HTMLDivElement>) => {
            if (onClickHandler) {
                event.stopPropagation();
                event.preventDefault();
                onClickHandler(sport.id);
            }
        },
        [onClickHandler, sport],
    );

    const renderContent = useMemo(
        () => (
            <>
                {hasFavorite && (
                    <Svg
                        src="/common/mobile/base-icons/favourites.svg"
                        wrapper="span"
                        className={styles.hasFavourite}
                    />
                )}

                {showGamesCount && <span className={styles.gamesCount}>{sport.games_count}</span>}
                <SportIcon sportId={sport.id} />
                <span className={styles.sportName}>{sport.name}</span>
            </>
        ),
        [hasFavorite, showGamesCount, sport.games_count, sport.id, sport.name],
    );

    const getLink = useCallback(() => {
        const pathReplaced = `${pathname
            ?.replaceAll(`/${RouteNames.LIVE}/${RouteNames.TOP}`, `/${RouteNames.LIVE}`)
            .replaceAll(`/${RouteNames.FAVORITES}`, `/${RouteNames.ALL}`)}`;

        if (!params?.sport) {
            return `${pathReplaced}/${sport.slug}`;
        }

        const pathSegments = pathReplaced.split("/");
        pathSegments[pathSegments.length - 1] = sport.slug;

        return pathSegments.join("/");
    }, [params?.sport, pathname, sport.slug]);

    const renderType = useMemo(() => {
        switch (variant) {
            case "button":
                return (
                    <div
                        className={classNames(active && styles.activeBtn, styles.sportItem)}
                        onClick={onClick}>
                        {renderContent}
                    </div>
                );
            default:
                return (
                    <ActiveLink
                        href={{
                            pathname: getLink(),
                        }}
                        shallow
                        forceActive={active}
                        activeClassName={styles.active}
                        className={styles.sportItem}
                        onClick={onClick}>
                        {renderContent}
                    </ActiveLink>
                );
        }
    }, [variant, active, onClick, renderContent, getLink]);

    return renderType;
};

export default memo(Sport);
